# import InfiniteLoading from 'vue-infinite-loading'
import Vue from 'vue/dist/vue.esm'
import VCalendar from 'v-calendar'
import TurbolinksAdapter from 'vue-turbolinks'
# import _ from 'lodash'
import Moment from 'moment'
import AxiosRequest from "../utils/axios-request"

# FILTERS
import toCurrency from '../filters/to_currency'

# Local imports
import AddCart from '../methods/products/add_cart'
import Delivery from '../methods/products/delivery'

Vue.use TurbolinksAdapter
Vue.use VCalendar

document.addEventListener 'turbolinks:load', ->
  el = document.getElementById 'show_service'
  return false unless el?

  dev = document.getElementById('dev').dataset.dev

  if JSON.parse dev
    router_api = 'http://dev.allkryon.com:3006'
  else
    router_api = 'https://api.kryon.app'

  new Vue {
    el
    filters: {toCurrency}
    data: -> {
      id: 0
      service: []
      favourites: []
      favourite: false
      selected_shipping: undefined
      selected_donate: undefined
      selected_pickup: undefined
      selected_date: undefined
      selected_store_id: undefined
      selected_foundation_id: undefined
      selected_address: undefined
      date: undefined
      price_shipping: 0
      available_dates: undefined
      available_times: undefined
      holidays: undefined
      last_service: undefined
      time_select: undefined
      return_cause: undefined
      attributes: [
        {
          dot: 'blue'
          dates: undefined
          popover: {
            label: 'Día festivo'
          }
        }
      ]
    }
    mounted: ->
      @getHolidays()
      @id = document.getElementById('id').dataset.id
      title = document.getElementById('title').dataset.title
      dev = document.getElementById('dev').dataset.dev
      query = $.get "/servicios/#{title}-id_#{@id}.json"
      query.done (res) =>
        @service = res.service
        @favourites = res.favourites
        @getStore(@id)
    methods: {
      AddCart
      Delivery
      getStore: (id) ->
        $.get("#{router_api}/api/v1/services/#{id}/available_dates.json").done (data) =>
          for available_date, index in data.available_dates
            if index == 0
              @selected_store_id = available_date.store.id
              @available_dates = available_date?.dates.map((e) => Moment(e).format('YYYY-M-DD'))
              $.get("#{router_api}/api/v1/services/#{id}/stores/#{@selected_store_id}/hours", { date: Moment(@date).format('YYYY-MM-DD') }).done (hours) =>
                @available_times = data.hours.map((e) => { hour: Moment.parseZone(e).format('h:mm A'), main: e})
      availableDate: (store) ->
        $.get("#{router_api}/api/v1/services/#{@id}/available_dates.json").done (data) =>
          find_dates = data.available_dates.find((e) => e.store.id == store)
          @available_dates = find_dates?.dates.map((e) => Moment(e).format('YYYY-M-DD'))
      availableHour: (date, store) ->
        $.get("#{router_api}/api/v1/services/#{@id}/stores/#{store}/hours", { date: Moment(date).format('YYYY-MM-DD') }).done (data) =>
          @available_times = data.hours.map((e) => { hour: Moment.parseZone(e).format('h:mm A'), main: e})
      getHolidays: () ->
        $.get("/servicios/holidays.json").done (data) =>
          @holidays = data.holidays.map((e) => new Date(Moment(e.date).format('YYYY, M, DD')))
          @attributes[0].dates = @holidays
      isHoliday: (date) ->
        @holidays?.some((e) => Moment(e).format('YYYY-M-DD') == Moment(date).format('YYYY-M-DD'))
      lastService: (store) ->
        index = @service.stores?.findIndex((e) => e.store_id == store)
        @last_service = @service.stores?[index].night_surcharge_hour
      isSameOrAfter: (last_service, time_select) ->
        Moment(time_select).isSameOrAfter(last_service)
      canAdd: () ->
        if @date && @time_select != undefined
          if @selected_shipping
            Flag = true if @selected_shipping
          else if @selected_pickup
            Flag = true if @selected_pickup
        else
          Flag = false
        not (Flag)
      DeleteFavourite: (service, uid) ->
        Swal.fire(
          title: '¿Estás seguro?'
          text: ''
          icon: 'warning'
          showCancelButton: true
          confirmButtonColor: '#30bbe5'
          cancelButtonColor: '#d33'
          cancelButtonText: 'Cancelar'
          confirmButtonText: 'Sí, quitar favorito').then (result) =>
            if result.value
              @favourite = false
              AxiosRequest("/customers/favourites/remove", {item_id: service, customer_id: uid}, 'post').then ({data}) =>
                Swal.fire({
                  title: data.message
                  icon: 'success'
                  toast: true
                  position: 'top-end'
                  timer: 2000
                })
            else if result.dismiss == Swal.DismissReason.cancel
              Swal.fire({
                title: 'Cancelado'
                icon: 'error'
                toast: true
                position: 'top-end'
                timer: 2000
              }).then ->
                console.log 'Cancelado'
            return
      Favourite: (service, uid) ->
        AxiosRequest('/customers/favourites', {item_id: service, customer_id: uid}, 'post').then ({data}) =>
          @favourite = true
          Swal.fire({
            title: data.message
            icon: 'success'
            toast: true
            position: 'top-end'
            timer: 2000
          }).then ->
            console.info 'success'
    }
    watch: {
      selected_store_id: (val) ->
        @date = undefined
        @time_select = undefined
        @lastService(val)
        @availableDate(val)
      date: (val) ->
        @availableHour(val, @selected_store_id)
    }
    computed: {
      any_delivery: ->
        flag = false
        if @selected_shipping == true || @selected_pickup == true
          flag = true
        flag
      any_option: ->
        flag = false
        if @selected_shipping == true || @selected_pickup == true || @date == null || @time_select == null
          flag = true
        flag
    }
  }
